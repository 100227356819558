<template>
  <div class="main-wrap">
    <router-view />
  </div>
</template>

<style lang="scss" scoped>
.main-wrap {
  background-color: #f0f0f0;
  flex: 1;
  overflow: hidden;
}
</style>
